import React, { useEffect, useState } from "react";

import bannerApe from "../../assets/CPAG2-0051 2.png";
import CPAtext from "../../assets/CYBER Punk APES.svg";
import Navbar from "../../Components/Navbar";
import OriginStory from "./OriginStory";
import Footer from "./Footer";
import { useNavigate } from "react-router";
import Showcase from "./Showcase";
import { Link } from "react-router-dom";
export default function Landing() {
  const navigate = useNavigate();


  // useEffect(() => {
  //   async function get() {}
  // }, []);
  return (
    <div>
      <>
        <Navbar showLogin={true} />
        <div className='pt-1 sm:pt-2 bg-[#150026]'>
          <div className='container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center pb-8 '>
            <div className='flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left'>
              <img src={CPAtext} className='w-3/5 md:w-3/5  mx-auto z-20' />
              <div className='w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0  lg:bg-transparent  p-4 lg:p-0 z-20'></div>
              {/* <Login /> */}
            </div>

            <div className='px-2 md:w-3/5 flex justify-center my-8 sm:my-0'>
              <img
                className='w-full md:w-3/5  rounded-md sm:rounded-lg shadow-purple-800 shadow-lg z-20  '
                src={bannerApe}
              />
             
          

            </div>
          </div>
        </div>

        <OriginStory />
        <Showcase />

        <Footer />
      </>
    </div>
  );
}
